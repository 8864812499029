@import url('https://fonts.googleapis.com/css?family=Abel|Source+Code+Pro:400,700&display=swap');

body {
    margin: 0;
    font-family: "Source Code Pro", monospace;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    color: white;
    background-color: black;
    user-select: none;
}

#root {
    width: 100vw;
    height: 100vh;
}


@keyframes arrow_animation {
    0% { padding-top: -5px; }
    50% { padding-top: 5px; }
    50% { padding-top: -10px; }
}

#poper {
    text-transform: none; 
}

#cookie-btn {
    color: #EBD999 !important;
    border-color: #EBD999 !important;
}

#newsletter_form a {
    color: grey !important;
}

.signUp {
    transition: all 0.7s ease;
    box-sizing: border-box;
}

.signUp:hover {
    text-shadow:
            -1px -1px 0 #aaa,
            1px -1px 0 #aaa,
            -1px 1px 0 #aaa,
            1px 1px 0 #aaa;
}

#page_newsletter_container {
    background: transparent;
    perspective: 5000px;
}

#page_newsletter {
    transform-style: preserve-3d;
}

#newsletter_form, #newsletter_form_status {
    padding: 18px 0;
    position: relative;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: all 0.5s ease;
    box-sizing: border-box;
}

.newsletter_form-flipped {
    transform: rotateX(180deg) !important; 
}

.newsletter_form_status-flipped {
    transform: rotateX(360deg) !important;
}

#newsletter_form {
    
}

#newsletter_form_status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateX(180deg);
}
#newsletter_form_status span {
    margin-top: -16px;
    position: relative;
    display: inline-block;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
}

#newsletter_checkbox:after {
    content: "\2002";
    width: 100%;
    height: 100%;
    border: 2px solid white;
    background-color: black;
    display: inline-block;
    visibility: visible;
    color: white;
    box-shadow: 0px 2px 4px rgba(155, 155, 155, 0.15);
}

#newsletter_checkbox:checked:after {
    content: "\2714";
    box-shadow: 0px 2px 4px rgba(155, 155, 155, 0.15);
    height: 100%;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: white;
}